ion-badge.badge {
    padding: 7px;
    position: absolute;
    top: 5px;
    right: 5px;
}
ion-badge.badge-report {
    padding: 7px;
}
.created{
    color: #6A6A6A;
    background-color: #f0f0f0;
    border-color: #6A6A6A;
    border-style: solid;
    border-width: 1px;
}
.in-progress{
    color: #2e75b6;
    background-color: #fefaee;
    border-color: #2e75b6;
    border-style: solid;
    border-width: 1px;
}
.in-approval{
    color: #F7C627;
    background-color: #fefaee;
    border-color: #F7C627;
    border-style: solid;
    border-width: 1px;
}
.reject {
    color: #FF4343;
    background-color: #fff0f0;
    border-color: #FF4343;
    border-style: solid;
    border-width: 1px;
}

.approved{
    color: #60AD4D;
    background-color: #f2f8f1;
    border-color: #60AD4D;
    border-style: solid;
    border-width: 1px;
}



.text-approved{
    color: #60AD4D;
}