button.camera{
    height:51px;
    width:51px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding:0;
}
button.shoot{
    background-color: white;
}

button.deletePicture{
    background-color: red;
}

button.savePicture{
    background-color: rgb(77, 255, 0);
}
button.onModify{
    background-color: rgb(255, 204, 0);
}


div.camera{
    position: relative;
    display: flex;
    justify-content: center;
}
div.camera > video{
    pointer-events: none;
}

div.camera-content{
    display: flex;
    flex-direction: column;
    align-items: center;
}

div.cameraBottom{
    position: absolute;
    bottom:0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width:100%;
    padding: 1em 1em;

}

img.picturePreview{
    max-width:100%;
    max-height: 80vh;
    image-rendering: -webkit-optimize-contrast;
}

div.cameraTop{
    position: absolute;
    top:0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width:100%;
    padding: 1em 1em; 
}
div.cameraTop.end{
    justify-content: end;
}

div.camera:not(.showCamera) > video{
    display: none;
}

div.camera > video{
    max-width:100%
}

