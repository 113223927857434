:root{
  --danger-color: #eb445a;
  --bg-grey-medium: #D1D3D3;
  --bg-grey-ultra-light: #F6F9F9;
  --bg-grey-very-dark: #424242;
}

.col {
  flex: 1 0 0%;
}

.pageContainer{
  margin-top:80px !important;
}

div.salvaMobile{
  position: fixed;
  bottom:0;
  left:0;
  display: block;
  width:100%;
  background-color: white;
  z-index: 3;
}

div.footer{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f3f3;
}


ion-button.button-solid::part(native){
  padding-left: 1.1rem;
  padding-right: 1.1rem;
}

.bg-list-white-settings{
  background-color: #FFFFFF;
}
ion-row.bg-list-white-settings:nth-child(2n){
  background-color: transparent;
}

.bg-list-white-group{
  background-color: #F6F9F9;
}
ion-row.bg-list-white-group:nth-child(2n){
  background-color: #FFFFFF;
}


h1, h2, h3, h3, h4, h5, h6 {
  font-weight: bold;
}
.absolute-required-field{
  position: absolute;
  bottom: -16.5rem;
}
.relative-required-field{
  position: relative;
  display: block;
  bottom: -16.5rem;
}
.absolute-required-field-detail{
  position: absolute;
  bottom: -32px;
}

.absolute-custom{
  position: absolute;
}

/* bg color */
.bg-white {
  background-color: #FFFFFF;
}
.bg-black {
  background-color: #000000;
}
.bg-darkgray {
  background-color: #a0a0a0;
  opacity: 0.8;
}
.bg-greyMedium {
  background-color: var(--bg-grey-medium);
}
.bg-greyUltraLight {
  background-color: var(--bg-grey-ultra-light) !important;
}
.bg-greyVeryDark {
  background-color: var(--bg-grey-very-dark);
}

/* text color */
.text-danger{
  color: var(--danger-color);
}
.text-white{
  color: #FFFFFF;
}

ion-icon.icon-data-general{
  vertical-align: middle;
  font-size: 1.5rem;
  padding-left: 0.5rem;
}

ion-col.my-thin-col {
  flex-basis: 0.2rem !important;
  padding:0;
}
ion-col.my-thin-col-select {
  flex-basis: 10% !important;
  padding:0;
}
ion-col.my-thin-col-problematic-img {
  flex-basis: 1% !important;
  padding:0;
}
.vh-100 {
  height: 100vh !important;
}
.vh-90 {
  height: 90vh !important;
}
.vh-80 {
  height: 80vh !important;
}
.vh-70 {
  height: 70vh !important;
}
.vh-60 {
  height: 60vh !important;
}
.w-100{
  width: 100%  !important;
}
.w-95{
  width: 95%  !important;
}
.w-94{
  width: 94%  !important;
}
.w-75{
  width: 75% !important;
}
.w-76{
  width: 76% !important;
}
.w-77{
  width: 77% !important;
}
.w-82{
  width: 82% !important;
}
.w-88{
  width: 88% !important;
}

.w-965{
  width: 96.5%;
}
.w-93{
  width: 93%;
}
.w-90{
  width: 90%;
}
.w-48{
  width: 48%;
}

/* .img-problem-slideshow {
  width: 100%;
  height: 50%;
  object-fit: inherit;
  object-position: inherit;
} */

.button-center {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  box-sizing: content-boxes;
  transform: translate(-50%, -50%);
}
.button-left {
  position: absolute;
  top: 54%;
  right: 0%;
  display: block;
  box-sizing: content-boxes;
  transform: translate(-50%, -50%);
}


.text-problematic-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 100%);
  font-weight: bold;
  color: #D1D3D3;
}
.text-photo-error {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, 100%);
  font-weight: bold;
  color: var(--bg-danger-color);
}

.text-problematic-center-bottom {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  font-weight: bold;
  color: #000000;
}
/* .slide-container{
  height: 17rem;
} */
.each-slide {
  height: 100%;
}
.each-slide>div {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  height: 16rem
  
}

.img-list-problematic{
   width: 100%;
   height: 65%;
}

.box-photo {
  box-sizing: border-box;
  border: 2px #D1D3D3 dotted;
  border-radius: 5px;
  background-color: #FFFFFF;
  height: 17rem;
}
.box-photo-error {
  box-sizing: border-box;
  border: 2px dotted var(--danger-color);
  border-radius: 5px;
  background-color: #FFFFFF;
  height: 300px;
}
.pointer {
  cursor: pointer;
}



.icon-data {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #6a6a6a;
}
.icon-data:hover .title-sidebar {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #33A7B2;
}

.icon-data-clicked {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #33A7B2;
}
a {
  color: #424242;
  text-decoration: none;
}

a:active {
  text-decoration: underline;
}
a:hover{
  color: #33A7B2;
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}
.rounded {
  border-radius: 0.25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.2rem !important;
}

.rounded-2 {
  border-radius: 0.25rem !important;
}

.rounded-3 {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.d-flex {
  display: flex;
}
.d-inline-flex {
  display: inline-flex !important;
}
.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

/* border */

.border {
  border: 1px solid #dee2e6 !important;
}
.border-error {
  border: 1px solid var(--danger-color)!important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #dee2e6 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #dee2e6 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

/* font */

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}
.fs-7 {
  font-size: 0.75rem !important;
}
.fs-8 {
  font-size: 0.5rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.label {
  display: block;
  color: #424242;
  font-size: 15px;
  font-weight: bold;
  text-transform: lowercase;
  line-height: 19px;
}

.dashed-border {
  border: #D8D8D8 dashed 1px;
  position: relative !important;
}

ion-icon::slotted(ion-icon) {
  font-size: 2rem;
}

#ion-sel-0 {
  border-color: red;
}

/* padding */

.pr-30 {
  padding-right: 30px;
}

.pl-30 {
  padding-left: 30px;
}

.px-30 {
  padding-right: 30px;
  padding-left: 30px;
}

.p-30 {
  padding: 30px;
}

.pl-5 {
  padding-left: 5px;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.p-6 {
  padding: 4rem;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pt-6 {
  padding-top: 4rem;
}

.pt-7 {
  padding-top: 5rem;
}

.pt-8 {
  padding-top: 5rem;
}

.pt-9 {
  padding-top: 6rem;
}

.pt-10 {
  padding-top: 7rem;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-30 {
  padding-right: 30px;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.ps-5_5 {
  padding-left: 3.5rem !important;
}

/* margin */

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-30 {
  margin-right: 1.875rem !important;
  margin-left: 1.875rem !important;
}
@media screen and ( max-width: 760px) {
  .mx-30 {
    margin-right: .875rem !important;
    margin-left: .875rem !important;
  }
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}
.mx-6 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important;
}
.mx-7 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}
.mx-8 {
  margin-right: 0.875rem !important;
  margin-left: 0.875rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}
.mt-4_4 {
  margin-top: 2.4rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-6 {
  margin-top: 4rem;
}

.mt-7 {
  margin-top: 5rem;
}

.mt-8 {
  margin-top: 6rem;
}

.mt-9 {
  margin-top: 7rem;
}

.mt-10 {
  margin-top: 8rem !important;
}

.mt-11 {
  margin-top: 9rem !important;
}

.mt-12 {
  margin-top: 10rem !important;
}

.mt-13 {
  margin-top: 11rem !important;
}
.mt-14 {
  margin-top: 12rem !important;
}
.mt-15 {
  margin-top: 13rem !important;
}
.mt-16 {
  margin-top: 14rem !important;
}
.mt-17 {
  margin-top: 15rem !important;
}
.mt-18 {
  margin-top: 16rem !important;
}
.mt-19 {
  margin-top: 17rem !important;
}
.mt-20 {
  margin-top: 18rem !important;
}
.mt-21 {
  margin-top: 19rem !important;
}
.mt-20 {
  margin-top: 18rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-30 {
  margin-right: 30px !important;
}

.me-01 {
  margin-right: 0.1 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-5_5 {
  margin-bottom: 3.5rem !important;
}

.mb-6{
  margin-bottom: 4rem !important;

}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-4_5 {
  margin-left: 2rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-5_5 {
  padding-left: 3.5rem !important;
}
.ms-6 {
  padding-left: 4rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.h-100 {
  height: 100% !important;
}
.flex-grow{
  flex: 1 0 auto !important;
}