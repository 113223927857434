button.camera{
    height:51px;
    width:51px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding:0;
}
button.exit{
    height:31px;
    width:31px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding:0;
}
button.shoot{
    background-color: white;
}

button.deletePicture{
    background-color: red;
}

button.savePicture{
    background-color: rgb(77, 255, 0);
}
button.onModify{
    background-color: rgb(255, 204, 0);
}


div.camera{
    position: relative;
    display: flex;
    justify-content: center;
}
div.camera > video{
    pointer-events: none;
}

div.camera-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
div.zoomtest{
    height: 100%;
    width: 100%;
    z-index: 50000;
    position: absolute;
    top:0;
    left:0;
}

div.cameraBottom{
    position: absolute;
    bottom:0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width:100%;
    padding: 1em 1em;
z-index: 3;
}
div.cameraRight{
    position: absolute;
    top:0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height:100%;
    padding: 1em 1em;
    width:80px;
    /* z-index: 1; */
}

input.rangeZoom{
    transform: rotate(270deg);
    min-width: 150px;
}

img.picturePreview{
    max-width:100%;
    max-height:100%;
    image-rendering: -webkit-optimize-contrast;
}

div.cameraTop{
    position: absolute;
    top:0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width:100%;
    padding: 1em 1em; 
    z-index: 2;
}
div.cameraTop.end{
    justify-content: flex-end;
}

div.camera:not(.showCamera) > video{
    display: none;
}

div.camera > video{
    max-width:100%;
    pointer-events: none !important;
    /* max-height: 100%; */
}

div.camera{
    overflow: hidden;
}

/* div.fakeBackdrop{
    visibility: hidden;
    z-index: 100000;
    background-color: rgba(0,0,0,0.2);
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
} */

div.fakeBackdrop{
    visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed; /* Stay in place */
    z-index: 10000; /* Sit on top */
    padding-top: 0px; /* Location of the box */
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    /* overflow: auto;  */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

div.fakeBackdrop.show{
    visibility: visible;
    /* display: block; */
}

div.intestazioneModal{
    position: absolute;
    top:0;
    left:0;
    width:100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color:#000;
    padding: 15px 10px;
    z-index: 2;
}

/* div.fakeDialog{
background-color: white;
min-height: 70%;
min-width: 50%;
display: flex;
} */

div.fakeDialog{
    background-color: rgb(4, 4, 4);
    margin: auto;
    /* min-height: 50vh; */
    /* padding: 20px; */
    /* border: 1px solid #888; */
    /* width: 60%; */
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    
    div.fakeDialog{
        padding: 5px;
        width: 100%;
        /* height:100% */
    }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    
    div.fakeDialog{
        padding: 5px;
        width: 100%;
    }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    
    div.fakeDialog{
        padding: 10px;
        width: 90%;
    }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    
    div.fakeDialog{
        padding: 15px;
        width: 80%;
    }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    
    div.fakeDialog{
        padding: 20px;
        width: 60%;
    }
}