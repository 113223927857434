.box {
    background-color: #ffffff;
    text-align: center;
    height: 322px;
    border-radius: 5px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.logo-login {
    max-height: 50px;
    width: auto;
}

.bg-login {
    background: url('../../assets/img/bg-login.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    /* Set rules to fill background */
    min-height: 100%;
    min-width: 1024px;
    /* Set up proportionate scaling */
    width: 100%;
    height: auto;
    /* Set up positioning */
    position: fixed;
    top: 0;
    left: 0;
}

@media screen and (max-width: 1024px) {
    /* Specific to this particular image */
    .bg-login {
        left: 50%;
        margin-left: -512px;
        /* 50% */
    }
    .box {
        background-color: #ffffff;
        text-align: center;
        height: 400px;
        border-radius: 5px;
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}