/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/* Set variables for all modes */

:root {
  /* Set the font family of the entire app */
  --ion-font-family: -apple-system, 'Montserrat', sans-serif;
}

:root {
  --ion-color-navbar: #ffffff;
  --ion-color-navbar-rgb: 255, 255, 255;
  --ion-color-navbar-contrast: #000000;
  --ion-color-navbar-contrast-rgb: 0, 0, 0;
  --ion-color-navbar-shade: #e0e0e0;
  --ion-color-navbar-tint: #ffffff;
}

:root {
  --ion-color-transparent: transparent;
  --ion-color-transparent-rgba: 0, 0, 0, 0;
  --ion-color-transparent-contrast: #000000;
  --ion-color-transparent-contrast-rgb: 0, 0, 0;
  --ion-color-transparent-shade: #e0e0e0;
  --ion-color-transparent-tint: #ffffff;
}
.ion-color-transparent {
  --ion-color-base: var(--ion-color-transparent);
  --ion-color-base-rgb: var(--ion-color-transparent-rgb);
  --ion-color-contrast: var(--ion-color-transparent-contrast);
  --ion-color-contrast-rgb: var(--ion-color-transparent-contrast-rgb);
  --ion-color-shade: var(--ion-color-transparent-shade);
  --ion-color-tint: var(--ion-color-transparent-tint);
}


.ion-color-navbar {
  --ion-color-base: var(--ion-color-navbar);
  --ion-color-base-rgb: var(--ion-color-navbar-rgb);
  --ion-color-contrast: var(--ion-color-navbar-contrast);
  --ion-color-contrast-rgb: var(--ion-color-navbar-contrast-rgb);
  --ion-color-shade: var(--ion-color-navbar-shade);
  --ion-color-tint: var(--ion-color-navbar-tint);
}

:root {
  /* Set the background of the entire app */
  --ion-background-color: #f3f3f3;
  --ion-background-color-rgb: 243, 243, 243;
  --ion-text-color: #424242;
  --ion-text-color-rgb: 66, 66, 66;
  --ion-color-step-50: #eaeaea;
  --ion-color-step-100: #e1e1e1;
  --ion-color-step-150: #d8d8d8;
  --ion-color-step-200: #d0d0d0;
  --ion-color-step-250: #c7c7c7;
  --ion-color-step-300: #bebebe;
  --ion-color-step-350: #b5b5b5;
  --ion-color-step-400: #acacac;
  --ion-color-step-450: #a3a3a3;
  --ion-color-step-500: #9b9b9b;
  --ion-color-step-550: #929292;
  --ion-color-step-600: #898989;
  --ion-color-step-650: #808080;
  --ion-color-step-700: #777777;
  --ion-color-step-750: #6e6e6e;
  --ion-color-step-800: #656565;
  --ion-color-step-850: #424242;
  --ion-color-step-900: #545454;
  --ion-color-step-950: #4b4b4b;
}

/** Ionic CSS Variables **/

:root {
  /** primary **/
  --ion-color-primary: #33A7B2;
  --ion-color-primary-rgb: 51, 167, 178;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #2d939d;
  --ion-color-primary-tint: #47b0ba;
  /** secondary **/
  --ion-color-secondary: #424242;
  --ion-color-secondary-rgb: 66, 66, 66;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #3a3a3a;
  --ion-color-secondary-tint: #555555;
  /** white **/
  --ion-color-white: #424242;
  --ion-color-white-rgb: 66, 66, 66;
  --ion-color-white-contrast: #ffffff;
  --ion-color-white-contrast-rgb: 255, 255, 255;
  --ion-color-white-shade: #3a3a3a;
  --ion-color-white-tint: #555555;
  /** tertiary **/
  --ion-color-tertiary: #33A7B2;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;
  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;
  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;
  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;
  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;
  /** light **/
  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #424242;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

