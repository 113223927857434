/* @media only screen and (orientation:portrait) {
    #report {
        height: 100vw;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
    }
    #report-menuMobile {
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
    }
} */

/* @media only screen and (orientation:landscape) {
    #report {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
} */


@media (min-width: 0px) {
    .button-left {
        position: absolute;
        top: 54%;
        right: -7%;
        display: block;
        box-sizing: content-boxes;
        transform: translate(-50%, -50%);
    }
    .w-xs-82 {
        width: 88% !important;
    }
    .w-xs-88 {
        width: 88% !important;
    }
    .ps-xs-0 {
        padding-left: 0 !important;
    }
    .ps-xs-1 {
        padding-left: 0.25rem !important;
    }
    .ps-xs-2 {
        padding-left: 0.5rem !important;
    }
    .ps-xs-3 {
        padding-left: 1rem !important;
    }
    .ps-xs-4 {
        padding-left: 1.5rem !important;
    }
    .ps-xs-5 {
        padding-left: 3rem !important;
    }
    .ms-xs-0 {
        margin-left: 0 !important;
    }
    .ms-xs-1 {
        margin-left: 0.25rem !important;
    }
    .ms-xs-2 {
        margin-left: 0.5rem !important;
    }
    .ms-xs-3 {
        margin-left: 1rem !important;
    }
    .ms-xs-4 {
        margin-left: 1.5rem !important;
    }
    .ms-xs-5 {
        margin-left: 3rem !important;
    }
    .ms-xs-auto {
        margin-left: auto !important;
    }
    .mt-xs-0 {
        margin-top: 0 !important;
    }
    .mt-xs-1 {
        margin-top: 0.25rem !important;
    }
    .mt-xs-2 {
        margin-top: 0.5rem !important;
    }
    .mt-xs-3 {
        margin-top: 1rem !important;
    }
    .mt-xs-4 {
        margin-top: 1.5rem !important;
    }
    .mt-xs-4_4 {
        margin-top: 2rem !important;
    }
    .mt-xs-5 {
        margin-top: 3rem !important;
    }
    .mt-xs-auto {
        margin-top: auto !important;
    }
}

@media (min-width: 576px) {
    .button-left {
        position: absolute;
        top: 54%;
        right: 3%;
        display: block;
        box-sizing: content-boxes;
        transform: translate(-50%, -50%);
    }
    .float-sm-start {
        float: left !important;
    }
    .float-sm-end {
        float: right !important;
    }
    .float-sm-none {
        float: none !important;
    }
    .d-sm-inline {
        display: inline !important;
    }
    .d-sm-inline-block {
        display: inline-block !important;
    }
    .d-sm-block {
        display: block !important;
    }
    .d-sm-grid {
        display: grid !important;
    }
    .d-sm-table {
        display: table !important;
    }
    .d-sm-table-row {
        display: table-row !important;
    }
    .d-sm-table-cell {
        display: table-cell !important;
    }
    .d-sm-flex {
        display: flex !important;
    }
    .d-sm-inline-flex {
        display: inline-flex !important;
    }
    .d-sm-none {
        display: none !important;
    }
    .flex-sm-fill {
        flex: 1 1 auto !important;
    }
    .flex-sm-row {
        flex-direction: row !important;
    }
    .flex-sm-column {
        flex-direction: column !important;
    }
    .flex-sm-row-reverse {
        flex-direction: row-reverse !important;
    }
    .flex-sm-column-reverse {
        flex-direction: column-reverse !important;
    }
    .flex-sm-grow-0 {
        flex-grow: 0 !important;
    }
    .flex-sm-grow-1 {
        flex-grow: 1 !important;
    }
    .flex-sm-shrink-0 {
        flex-shrink: 0 !important;
    }
    .flex-sm-shrink-1 {
        flex-shrink: 1 !important;
    }
    .flex-sm-wrap {
        flex-wrap: wrap !important;
    }
    .flex-sm-nowrap {
        flex-wrap: nowrap !important;
    }
    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }
    .gap-sm-0 {
        gap: 0 !important;
    }
    .gap-sm-1 {
        gap: 0.25rem !important;
    }
    .gap-sm-2 {
        gap: 0.5rem !important;
    }
    .gap-sm-3 {
        gap: 1rem !important;
    }
    .gap-sm-4 {
        gap: 1.5rem !important;
    }
    .gap-sm-5 {
        gap: 3rem !important;
    }
    .justify-content-sm-start {
        justify-content: flex-start !important;
    }
    .justify-content-sm-end {
        justify-content: flex-end !important;
    }
    .justify-content-sm-center {
        justify-content: center !important;
    }
    .justify-content-sm-between {
        justify-content: space-between !important;
    }
    .justify-content-sm-around {
        justify-content: space-around !important;
    }
    .justify-content-sm-evenly {
        justify-content: space-evenly !important;
    }
    .align-items-sm-start {
        align-items: flex-start !important;
    }
    .align-items-sm-end {
        align-items: flex-end !important;
    }
    .align-items-sm-center {
        align-items: center !important;
    }
    .align-items-sm-baseline {
        align-items: baseline !important;
    }
    .align-items-sm-stretch {
        align-items: stretch !important;
    }
    .align-content-sm-start {
        align-content: flex-start !important;
    }
    .align-content-sm-end {
        align-content: flex-end !important;
    }
    .align-content-sm-center {
        align-content: center !important;
    }
    .align-content-sm-between {
        align-content: space-between !important;
    }
    .align-content-sm-around {
        align-content: space-around !important;
    }
    .align-content-sm-stretch {
        align-content: stretch !important;
    }
    .align-self-sm-auto {
        align-self: auto !important;
    }
    .align-self-sm-start {
        align-self: flex-start !important;
    }
    .align-self-sm-end {
        align-self: flex-end !important;
    }
    .align-self-sm-center {
        align-self: center !important;
    }
    .align-self-sm-baseline {
        align-self: baseline !important;
    }
    .align-self-sm-stretch {
        align-self: stretch !important;
    }
    .order-sm-first {
        order: -1 !important;
    }
    .order-sm-0 {
        order: 0 !important;
    }
    .order-sm-1 {
        order: 1 !important;
    }
    .order-sm-2 {
        order: 2 !important;
    }
    .order-sm-3 {
        order: 3 !important;
    }
    .order-sm-4 {
        order: 4 !important;
    }
    .order-sm-5 {
        order: 5 !important;
    }
    .order-sm-last {
        order: 6 !important;
    }
    .m-sm-0 {
        margin: 0 !important;
    }
    .m-sm-1 {
        margin: 0.25rem !important;
    }
    .m-sm-2 {
        margin: 0.5rem !important;
    }
    .m-sm-3 {
        margin: 1rem !important;
    }
    .m-sm-4 {
        margin: 1.5rem !important;
    }
    .m-sm-5 {
        margin: 3rem !important;
    }
    .m-sm-auto {
        margin: auto !important;
    }
    .mx-sm-0 {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    .mx-sm-1 {
        margin-right: 0.25rem !important;
        margin-left: 0.25rem !important;
    }
    .mx-sm-2 {
        margin-right: 0.5rem !important;
        margin-left: 0.5rem !important;
    }
    .mx-sm-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important;
    }
    .mx-sm-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
    }
    .mx-sm-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important;
    }
    .mx-sm-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
    .my-sm-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
    .my-sm-1 {
        margin-top: 0.25rem !important;
        margin-bottom: 0.25rem !important;
    }
    .my-sm-2 {
        margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;
    }
    .my-sm-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }
    .my-sm-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important;
    }
    .my-sm-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important;
    }
    .my-sm-auto {
        margin-top: auto !important;
        margin-bottom: auto !important;
    }
    .mt-sm-0 {
        margin-top: 0 !important;
    }
    .mt-sm-1 {
        margin-top: 0.25rem !important;
    }
    .mt-sm-2 {
        margin-top: 0.5rem !important;
    }
    .mt-sm-3 {
        margin-top: 1rem !important;
    }
    .mt-sm-4 {
        margin-top: 1.5rem !important;
    }
    .mt-sm-5 {
        margin-top: 3rem !important;
    }
    .mt-sm-auto {
        margin-top: auto !important;
    }
    .me-sm-0 {
        margin-right: 0 !important;
    }
    .me-sm-1 {
        margin-right: 0.25rem !important;
    }
    .me-sm-2 {
        margin-right: 0.5rem !important;
    }
    .me-sm-3 {
        margin-right: 1rem !important;
    }
    .me-sm-4 {
        margin-right: 1.5rem !important;
    }
    .me-sm-5 {
        margin-right: 3rem !important;
    }
    .me-sm-auto {
        margin-right: auto !important;
    }
    .mb-sm-0 {
        margin-bottom: 0 !important;
    }
    .mb-sm-1 {
        margin-bottom: 0.25rem !important;
    }
    .mb-sm-2 {
        margin-bottom: 0.5rem !important;
    }
    .mb-sm-3 {
        margin-bottom: 1rem !important;
    }
    .mb-sm-4 {
        margin-bottom: 1.5rem !important;
    }
    .mb-sm-5 {
        margin-bottom: 3rem !important;
    }
    .mb-sm-auto {
        margin-bottom: auto !important;
    }
    .ms-sm-0 {
        margin-left: 0 !important;
    }
    .ms-sm-1 {
        margin-left: 0.25rem !important;
    }
    .ms-sm-2 {
        margin-left: 0.5rem !important;
    }
    .ms-sm-3 {
        margin-left: 1rem !important;
    }
    .ms-sm-4 {
        margin-left: 1.5rem !important;
    }
    .ms-sm-5 {
        margin-left: 3rem !important;
    }
    .ms-sm-auto {
        margin-left: auto !important;
    }
    .p-sm-0 {
        padding: 0 !important;
    }
    .p-sm-1 {
        padding: 0.25rem !important;
    }
    .p-sm-2 {
        padding: 0.5rem !important;
    }
    .p-sm-3 {
        padding: 1rem !important;
    }
    .p-sm-4 {
        padding: 1.5rem !important;
    }
    .p-sm-5 {
        padding: 3rem !important;
    }
    .px-sm-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
    .px-sm-1 {
        padding-right: 0.25rem !important;
        padding-left: 0.25rem !important;
    }
    .px-sm-2 {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }
    .px-sm-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }
    .px-sm-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }
    .px-sm-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
    }
    .py-sm-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    .py-sm-1 {
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
    }
    .py-sm-2 {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }
    .py-sm-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }
    .py-sm-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
    }
    .py-sm-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
    }
    .pt-sm-0 {
        padding-top: 0 !important;
    }
    .pt-sm-1 {
        padding-top: 0.25rem !important;
    }
    .pt-sm-2 {
        padding-top: 0.5rem !important;
    }
    .pt-sm-3 {
        padding-top: 1rem !important;
    }
    .pt-sm-4 {
        padding-top: 1.5rem !important;
    }
    .pt-sm-5 {
        padding-top: 3rem !important;
    }
    .pe-sm-0 {
        padding-right: 0 !important;
    }
    .pe-sm-1 {
        padding-right: 0.25rem !important;
    }
    .pe-sm-2 {
        padding-right: 0.5rem !important;
    }
    .pe-sm-3 {
        padding-right: 1rem !important;
    }
    .pe-sm-4 {
        padding-right: 1.5rem !important;
    }
    .pe-sm-5 {
        padding-right: 3rem !important;
    }
    .pb-sm-0 {
        padding-bottom: 0 !important;
    }
    .pb-sm-1 {
        padding-bottom: 0.25rem !important;
    }
    .pb-sm-2 {
        padding-bottom: 0.5rem !important;
    }
    .pb-sm-3 {
        padding-bottom: 1rem !important;
    }
    .pb-sm-4 {
        padding-bottom: 1.5rem !important;
    }
    .pb-sm-5 {
        padding-bottom: 3rem !important;
    }
    .ps-sm-0 {
        padding-left: 0 !important;
    }
    .ps-sm-1 {
        padding-left: 0.25rem !important;
    }
    .ps-sm-2 {
        padding-left: 0.5rem !important;
    }
    .ps-sm-3 {
        padding-left: 1rem !important;
    }
    .ps-sm-4 {
        padding-left: 1.5rem !important;
    }
    .ps-sm-5 {
        padding-left: 3rem !important;
    }
    .text-sm-start {
        text-align: left !important;
    }
    .text-sm-end {
        text-align: right !important;
    }
    .text-sm-center {
        text-align: center !important;
    }
}

@media (min-width: 768px) {
    .button-left {
        position: absolute;
        top: 54%;
        right: 0%;
        display: block;
        box-sizing: content-boxes;
        transform: translate(-50%, -50%);
    }
    .float-md-start {
        float: left !important;
    }
    .float-md-end {
        float: right !important;
    }
    .float-md-none {
        float: none !important;
    }
    .d-md-inline {
        display: inline !important;
    }
    .d-md-inline-block {
        display: inline-block !important;
    }
    .d-md-block {
        display: block !important;
    }
    .d-md-grid {
        display: grid !important;
    }
    .d-md-table {
        display: table !important;
    }
    .d-md-table-row {
        display: table-row !important;
    }
    .d-md-table-cell {
        display: table-cell !important;
    }
    .d-md-flex {
        display: flex !important;
    }
    .d-md-inline-flex {
        display: inline-flex !important;
    }
    .d-md-none {
        display: none !important;
    }
    .flex-md-fill {
        flex: 1 1 auto !important;
    }
    .flex-md-row {
        flex-direction: row !important;
    }
    .flex-md-column {
        flex-direction: column !important;
    }
    .flex-md-row-reverse {
        flex-direction: row-reverse !important;
    }
    .flex-md-column-reverse {
        flex-direction: column-reverse !important;
    }
    .flex-md-grow-0 {
        flex-grow: 0 !important;
    }
    .flex-md-grow-1 {
        flex-grow: 1 !important;
    }
    .flex-md-shrink-0 {
        flex-shrink: 0 !important;
    }
    .flex-md-shrink-1 {
        flex-shrink: 1 !important;
    }
    .flex-md-wrap {
        flex-wrap: wrap !important;
    }
    .flex-md-nowrap {
        flex-wrap: nowrap !important;
    }
    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }
    .gap-md-0 {
        gap: 0 !important;
    }
    .gap-md-1 {
        gap: 0.25rem !important;
    }
    .gap-md-2 {
        gap: 0.5rem !important;
    }
    .gap-md-3 {
        gap: 1rem !important;
    }
    .gap-md-4 {
        gap: 1.5rem !important;
    }
    .gap-md-5 {
        gap: 3rem !important;
    }
    .justify-content-md-start {
        justify-content: flex-start !important;
    }
    .justify-content-md-end {
        justify-content: flex-end !important;
    }
    .justify-content-md-center {
        justify-content: center !important;
    }
    .justify-content-md-between {
        justify-content: space-between !important;
    }
    .justify-content-md-around {
        justify-content: space-around !important;
    }
    .justify-content-md-evenly {
        justify-content: space-evenly !important;
    }
    .align-items-md-start {
        align-items: flex-start !important;
    }
    .align-items-md-end {
        align-items: flex-end !important;
    }
    .align-items-md-center {
        align-items: center !important;
    }
    .align-items-md-baseline {
        align-items: baseline !important;
    }
    .align-items-md-stretch {
        align-items: stretch !important;
    }
    .align-content-md-start {
        align-content: flex-start !important;
    }
    .align-content-md-end {
        align-content: flex-end !important;
    }
    .align-content-md-center {
        align-content: center !important;
    }
    .align-content-md-between {
        align-content: space-between !important;
    }
    .align-content-md-around {
        align-content: space-around !important;
    }
    .align-content-md-stretch {
        align-content: stretch !important;
    }
    .align-self-md-auto {
        align-self: auto !important;
    }
    .align-self-md-start {
        align-self: flex-start !important;
    }
    .align-self-md-end {
        align-self: flex-end !important;
    }
    .align-self-md-center {
        align-self: center !important;
    }
    .align-self-md-baseline {
        align-self: baseline !important;
    }
    .align-self-md-stretch {
        align-self: stretch !important;
    }
    .order-md-first {
        order: -1 !important;
    }
    .order-md-0 {
        order: 0 !important;
    }
    .order-md-1 {
        order: 1 !important;
    }
    .order-md-2 {
        order: 2 !important;
    }
    .order-md-3 {
        order: 3 !important;
    }
    .order-md-4 {
        order: 4 !important;
    }
    .order-md-5 {
        order: 5 !important;
    }
    .order-md-last {
        order: 6 !important;
    }
    .m-md-0 {
        margin: 0 !important;
    }
    .m-md-1 {
        margin: 0.25rem !important;
    }
    .m-md-2 {
        margin: 0.5rem !important;
    }
    .m-md-3 {
        margin: 1rem !important;
    }
    .m-md-4 {
        margin: 1.5rem !important;
    }
    .m-md-5 {
        margin: 3rem !important;
    }
    .m-md-auto {
        margin: auto !important;
    }
    .mx-md-0 {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    .mx-md-1 {
        margin-right: 0.25rem !important;
        margin-left: 0.25rem !important;
    }
    .mx-md-2 {
        margin-right: 0.5rem !important;
        margin-left: 0.5rem !important;
    }
    .mx-md-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important;
    }
    .mx-md-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
    }
    .mx-md-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important;
    }
    .mx-md-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
    .my-md-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
    .my-md-1 {
        margin-top: 0.25rem !important;
        margin-bottom: 0.25rem !important;
    }
    .my-md-2 {
        margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;
    }
    .my-md-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }
    .my-md-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important;
    }
    .my-md-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important;
    }
    .my-md-auto {
        margin-top: auto !important;
        margin-bottom: auto !important;
    }
    .mt-md-0 {
        margin-top: 0 !important;
    }
    .mt-md-1 {
        margin-top: 0.25rem !important;
    }
    .mt-md-2 {
        margin-top: 0.5rem !important;
    }
    .mt-md-3 {
        margin-top: 1rem !important;
    }
    .mt-md-4 {
        margin-top: 1.5rem !important;
    }
    .mt-md-5 {
        margin-top: 3rem !important;
    }
    .mt-md-auto {
        margin-top: auto !important;
    }
    .me-md-0 {
        margin-right: 0 !important;
    }
    .me-md-1 {
        margin-right: 0.25rem !important;
    }
    .me-md-2 {
        margin-right: 0.5rem !important;
    }
    .me-md-3 {
        margin-right: 1rem !important;
    }
    .me-md-4 {
        margin-right: 1.5rem !important;
    }
    .me-md-5 {
        margin-right: 3rem !important;
    }
    .me-md-auto {
        margin-right: auto !important;
    }
    .mb-md-0 {
        margin-bottom: 0 !important;
    }
    .mb-md-1 {
        margin-bottom: 0.25rem !important;
    }
    .mb-md-2 {
        margin-bottom: 0.5rem !important;
    }
    .mb-md-3 {
        margin-bottom: 1rem !important;
    }
    .mb-md-4 {
        margin-bottom: 1.5rem !important;
    }
    .mb-md-5 {
        margin-bottom: 3rem !important;
    }
    .mb-md-auto {
        margin-bottom: auto !important;
    }
    .ms-md-0 {
        margin-left: 0 !important;
    }
    .ms-md-1 {
        margin-left: 0.25rem !important;
    }
    .ms-md-2 {
        margin-left: 0.5rem !important;
    }
    .ms-md-3 {
        margin-left: 1rem !important;
    }
    .ms-md-4 {
        margin-left: 1.5rem !important;
    }
    .ms-md-5 {
        margin-left: 3rem !important;
    }
    .ms-md-auto {
        margin-left: auto !important;
    }
    .p-md-0 {
        padding: 0 !important;
    }
    .p-md-1 {
        padding: 0.25rem !important;
    }
    .p-md-2 {
        padding: 0.5rem !important;
    }
    .p-md-3 {
        padding: 1rem !important;
    }
    .p-md-4 {
        padding: 1.5rem !important;
    }
    .p-md-5 {
        padding: 3rem !important;
    }
    .px-md-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
    .px-md-1 {
        padding-right: 0.25rem !important;
        padding-left: 0.25rem !important;
    }
    .px-md-2 {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }
    .px-md-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }
    .px-md-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }
    .px-md-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
    }
    .py-md-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    .py-md-1 {
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
    }
    .py-md-2 {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }
    .py-md-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }
    .py-md-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
    }
    .py-md-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
    }
    .pt-md-0 {
        padding-top: 0 !important;
    }
    .pt-md-1 {
        padding-top: 0.25rem !important;
    }
    .pt-md-2 {
        padding-top: 0.5rem !important;
    }
    .pt-md-3 {
        padding-top: 1rem !important;
    }
    .pt-md-4 {
        padding-top: 1.5rem !important;
    }
    .pt-md-5 {
        padding-top: 3rem !important;
    }
    .pe-md-0 {
        padding-right: 0 !important;
    }
    .pe-md-1 {
        padding-right: 0.25rem !important;
    }
    .pe-md-2 {
        padding-right: 0.5rem !important;
    }
    .pe-md-3 {
        padding-right: 1rem !important;
    }
    .pe-md-4 {
        padding-right: 1.5rem !important;
    }
    .pe-md-5 {
        padding-right: 3rem !important;
    }
    .pb-md-0 {
        padding-bottom: 0 !important;
    }
    .pb-md-1 {
        padding-bottom: 0.25rem !important;
    }
    .pb-md-2 {
        padding-bottom: 0.5rem !important;
    }
    .pb-md-3 {
        padding-bottom: 1rem !important;
    }
    .pb-md-4 {
        padding-bottom: 1.5rem !important;
    }
    .pb-md-5 {
        padding-bottom: 3rem !important;
    }
    .ps-md-0 {
        padding-left: 0 !important;
    }
    .ps-md-1 {
        padding-left: 0.25rem !important;
    }
    .ps-md-2 {
        padding-left: 0.5rem !important;
    }
    .ps-md-3 {
        padding-left: 1rem !important;
    }
    .ps-md-4 {
        padding-left: 1.5rem !important;
    }
    .ps-md-5 {
        padding-left: 3rem !important;
    }
    .text-md-start {
        text-align: left !important;
    }
    .text-md-end {
        text-align: right !important;
    }
    .text-md-center {
        text-align: center !important;
    }
}

@media (min-width: 992px) {
    .button-left {
        position: absolute;
        top: 54%;
        right: 3%;
        display: block;
        box-sizing: content-boxes;
        transform: translate(-50%, -50%);
    }
    .w-lg-82 {
        width: 82% !important;
    }
    .w-lg-94 {
        width: 94% !important;
    }
    .float-lg-start {
        float: left !important;
    }
    .float-lg-end {
        float: right !important;
    }
    .float-lg-none {
        float: none !important;
    }
    .d-lg-inline {
        display: inline !important;
    }
    .d-lg-inline-block {
        display: inline-block !important;
    }
    .d-lg-block {
        display: block !important;
    }
    .d-lg-grid {
        display: grid !important;
    }
    .d-lg-table {
        display: table !important;
    }
    .d-lg-table-row {
        display: table-row !important;
    }
    .d-lg-table-cell {
        display: table-cell !important;
    }
    .d-lg-flex {
        display: flex !important;
    }
    .d-lg-inline-flex {
        display: inline-flex !important;
    }
    .d-lg-none {
        display: none !important;
    }
    .flex-lg-fill {
        flex: 1 1 auto !important;
    }
    .flex-lg-row {
        flex-direction: row !important;
    }
    .flex-lg-column {
        flex-direction: column !important;
    }
    .flex-lg-row-reverse {
        flex-direction: row-reverse !important;
    }
    .flex-lg-column-reverse {
        flex-direction: column-reverse !important;
    }
    .flex-lg-grow-0 {
        flex-grow: 0 !important;
    }
    .flex-lg-grow-1 {
        flex-grow: 1 !important;
    }
    .flex-lg-shrink-0 {
        flex-shrink: 0 !important;
    }
    .flex-lg-shrink-1 {
        flex-shrink: 1 !important;
    }
    .flex-lg-wrap {
        flex-wrap: wrap !important;
    }
    .flex-lg-nowrap {
        flex-wrap: nowrap !important;
    }
    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }
    .gap-lg-0 {
        gap: 0 !important;
    }
    .gap-lg-1 {
        gap: 0.25rem !important;
    }
    .gap-lg-2 {
        gap: 0.5rem !important;
    }
    .gap-lg-3 {
        gap: 1rem !important;
    }
    .gap-lg-4 {
        gap: 1.5rem !important;
    }
    .gap-lg-5 {
        gap: 3rem !important;
    }
    .justify-content-lg-start {
        justify-content: flex-start !important;
    }
    .justify-content-lg-end {
        justify-content: flex-end !important;
    }
    .justify-content-lg-center {
        justify-content: center !important;
    }
    .justify-content-lg-between {
        justify-content: space-between !important;
    }
    .justify-content-lg-around {
        justify-content: space-around !important;
    }
    .justify-content-lg-evenly {
        justify-content: space-evenly !important;
    }
    .align-items-lg-start {
        align-items: flex-start !important;
    }
    .align-items-lg-end {
        align-items: flex-end !important;
    }
    .align-items-lg-center {
        align-items: center !important;
    }
    .align-items-lg-baseline {
        align-items: baseline !important;
    }
    .align-items-lg-stretch {
        align-items: stretch !important;
    }
    .align-content-lg-start {
        align-content: flex-start !important;
    }
    .align-content-lg-end {
        align-content: flex-end !important;
    }
    .align-content-lg-center {
        align-content: center !important;
    }
    .align-content-lg-between {
        align-content: space-between !important;
    }
    .align-content-lg-around {
        align-content: space-around !important;
    }
    .align-content-lg-stretch {
        align-content: stretch !important;
    }
    .align-self-lg-auto {
        align-self: auto !important;
    }
    .align-self-lg-start {
        align-self: flex-start !important;
    }
    .align-self-lg-end {
        align-self: flex-end !important;
    }
    .align-self-lg-center {
        align-self: center !important;
    }
    .align-self-lg-baseline {
        align-self: baseline !important;
    }
    .align-self-lg-stretch {
        align-self: stretch !important;
    }
    .order-lg-first {
        order: -1 !important;
    }
    .order-lg-0 {
        order: 0 !important;
    }
    .order-lg-1 {
        order: 1 !important;
    }
    .order-lg-2 {
        order: 2 !important;
    }
    .order-lg-3 {
        order: 3 !important;
    }
    .order-lg-4 {
        order: 4 !important;
    }
    .order-lg-5 {
        order: 5 !important;
    }
    .order-lg-last {
        order: 6 !important;
    }
    .m-lg-0 {
        margin: 0 !important;
    }
    .m-lg-1 {
        margin: 0.25rem !important;
    }
    .m-lg-2 {
        margin: 0.5rem !important;
    }
    .m-lg-3 {
        margin: 1rem !important;
    }
    .m-lg-4 {
        margin: 1.5rem !important;
    }
    .m-lg-5 {
        margin: 3rem !important;
    }
    .m-lg-auto {
        margin: auto !important;
    }
    .mx-lg-0 {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    .mx-lg-1 {
        margin-right: 0.25rem !important;
        margin-left: 0.25rem !important;
    }
    .mx-lg-2 {
        margin-right: 0.5rem !important;
        margin-left: 0.5rem !important;
    }
    .mx-lg-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important;
    }
    .mx-lg-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
    }
    .mx-lg-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important;
    }
    .mx-lg-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
    .my-lg-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
    .my-lg-1 {
        margin-top: 0.25rem !important;
        margin-bottom: 0.25rem !important;
    }
    .my-lg-2 {
        margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;
    }
    .my-lg-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }
    .my-lg-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important;
    }
    .my-lg-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important;
    }
    .my-lg-auto {
        margin-top: auto !important;
        margin-bottom: auto !important;
    }
    .mt-lg-0 {
        margin-top: 0 !important;
    }
    .mt-lg-1 {
        margin-top: 0.25rem !important;
    }
    .mt-lg-2 {
        margin-top: 0.5rem !important;
    }
    .mt-lg-3 {
        margin-top: 1rem !important;
    }
    .mt-lg-4 {
        margin-top: 1.5rem !important;
    }
    .mt-lg-5 {
        margin-top: 3rem !important;
    }
    .mt-lg-auto {
        margin-top: auto !important;
    }
    .me-lg-0 {
        margin-right: 0 !important;
    }
    .me-lg-1 {
        margin-right: 0.25rem !important;
    }
    .me-lg-2 {
        margin-right: 0.5rem !important;
    }
    .me-lg-3 {
        margin-right: 1rem !important;
    }
    .me-lg-4 {
        margin-right: 1.5rem !important;
    }
    .me-lg-5 {
        margin-right: 3rem !important;
    }
    .me-lg-auto {
        margin-right: auto !important;
    }
    .mb-lg-0 {
        margin-bottom: 0 !important;
    }
    .mb-lg-1 {
        margin-bottom: 0.25rem !important;
    }
    .mb-lg-2 {
        margin-bottom: 0.5rem !important;
    }
    .mb-lg-3 {
        margin-bottom: 1rem !important;
    }
    .mb-lg-4 {
        margin-bottom: 1.5rem !important;
    }
    .mb-lg-5 {
        margin-bottom: 3rem !important;
    }
    .mb-lg-auto {
        margin-bottom: auto !important;
    }
    .ms-lg-0 {
        margin-left: 0 !important;
    }
    .ms-lg-1 {
        margin-left: 0.25rem !important;
    }
    .ms-lg-2 {
        margin-left: 0.5rem !important;
    }
    .ms-lg-3 {
        margin-left: 1rem !important;
    }
    .ms-lg-4 {
        margin-left: 1.5rem !important;
    }
    .ms-lg-5 {
        margin-left: 3rem !important;
    }
    .ms-lg-auto {
        margin-left: auto !important;
    }
    .p-lg-0 {
        padding: 0 !important;
    }
    .p-lg-1 {
        padding: 0.25rem !important;
    }
    .p-lg-2 {
        padding: 0.5rem !important;
    }
    .p-lg-3 {
        padding: 1rem !important;
    }
    .p-lg-4 {
        padding: 1.5rem !important;
    }
    .p-lg-5 {
        padding: 3rem !important;
    }
    .px-lg-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
    .px-lg-1 {
        padding-right: 0.25rem !important;
        padding-left: 0.25rem !important;
    }
    .px-lg-2 {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }
    .px-lg-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }
    .px-lg-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }
    .px-lg-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
    }
    .py-lg-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    .py-lg-1 {
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
    }
    .py-lg-2 {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }
    .py-lg-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }
    .py-lg-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
    }
    .py-lg-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
    }
    .pt-lg-0 {
        padding-top: 0 !important;
    }
    .pt-lg-1 {
        padding-top: 0.25rem !important;
    }
    .pt-lg-2 {
        padding-top: 0.5rem !important;
    }
    .pt-lg-3 {
        padding-top: 1rem !important;
    }
    .pt-lg-4 {
        padding-top: 1.5rem !important;
    }
    .pt-lg-5 {
        padding-top: 3rem !important;
    }
    .pe-lg-0 {
        padding-right: 0 !important;
    }
    .pe-lg-1 {
        padding-right: 0.25rem !important;
    }
    .pe-lg-2 {
        padding-right: 0.5rem !important;
    }
    .pe-lg-3 {
        padding-right: 1rem !important;
    }
    .pe-lg-4 {
        padding-right: 1.5rem !important;
    }
    .pe-lg-5 {
        padding-right: 3rem !important;
    }
    .pb-lg-0 {
        padding-bottom: 0 !important;
    }
    .pb-lg-1 {
        padding-bottom: 0.25rem !important;
    }
    .pb-lg-2 {
        padding-bottom: 0.5rem !important;
    }
    .pb-lg-3 {
        padding-bottom: 1rem !important;
    }
    .pb-lg-4 {
        padding-bottom: 1.5rem !important;
    }
    .pb-lg-5 {
        padding-bottom: 3rem !important;
    }
    .ps-lg-0 {
        padding-left: 0 !important;
    }
    .ps-lg-1 {
        padding-left: 0.25rem !important;
    }
    .ps-lg-2 {
        padding-left: 0.5rem !important;
    }
    .ps-lg-3 {
        padding-left: 1rem !important;
    }
    .ps-lg-4 {
        padding-left: 1.5rem !important;
    }
    .ps-lg-5 {
        padding-left: 3rem !important;
    }
    .text-lg-start {
        text-align: left !important;
    }
    .text-lg-end {
        text-align: right !important;
    }
    .text-lg-center {
        text-align: center !important;
    }
}

@media (min-width: 1200px) {
    .button-left {
        position: absolute;
        top: 54%;
        right: 2%;
        display: block;
        box-sizing: content-boxes;
        transform: translate(-50%, -50%);
    }
    .float-xl-start {
        float: left !important;
    }
    .float-xl-end {
        float: right !important;
    }
    .float-xl-none {
        float: none !important;
    }
    .d-xl-inline {
        display: inline !important;
    }
    .d-xl-inline-block {
        display: inline-block !important;
    }
    .d-xl-block {
        display: block !important;
    }
    .d-xl-grid {
        display: grid !important;
    }
    .d-xl-table {
        display: table !important;
    }
    .d-xl-table-row {
        display: table-row !important;
    }
    .d-xl-table-cell {
        display: table-cell !important;
    }
    .d-xl-flex {
        display: flex !important;
    }
    .d-xl-inline-flex {
        display: inline-flex !important;
    }
    .d-xl-none {
        display: none !important;
    }
    .flex-xl-fill {
        flex: 1 1 auto !important;
    }
    .flex-xl-row {
        flex-direction: row !important;
    }
    .flex-xl-column {
        flex-direction: column !important;
    }
    .flex-xl-row-reverse {
        flex-direction: row-reverse !important;
    }
    .flex-xl-column-reverse {
        flex-direction: column-reverse !important;
    }
    .flex-xl-grow-0 {
        flex-grow: 0 !important;
    }
    .flex-xl-grow-1 {
        flex-grow: 1 !important;
    }
    .flex-xl-shrink-0 {
        flex-shrink: 0 !important;
    }
    .flex-xl-shrink-1 {
        flex-shrink: 1 !important;
    }
    .flex-xl-wrap {
        flex-wrap: wrap !important;
    }
    .flex-xl-nowrap {
        flex-wrap: nowrap !important;
    }
    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }
    .gap-xl-0 {
        gap: 0 !important;
    }
    .gap-xl-1 {
        gap: 0.25rem !important;
    }
    .gap-xl-2 {
        gap: 0.5rem !important;
    }
    .gap-xl-3 {
        gap: 1rem !important;
    }
    .gap-xl-4 {
        gap: 1.5rem !important;
    }
    .gap-xl-5 {
        gap: 3rem !important;
    }
    .justify-content-xl-start {
        justify-content: flex-start !important;
    }
    .justify-content-xl-end {
        justify-content: flex-end !important;
    }
    .justify-content-xl-center {
        justify-content: center !important;
    }
    .justify-content-xl-between {
        justify-content: space-between !important;
    }
    .justify-content-xl-around {
        justify-content: space-around !important;
    }
    .justify-content-xl-evenly {
        justify-content: space-evenly !important;
    }
    .align-items-xl-start {
        align-items: flex-start !important;
    }
    .align-items-xl-end {
        align-items: flex-end !important;
    }
    .align-items-xl-center {
        align-items: center !important;
    }
    .align-items-xl-baseline {
        align-items: baseline !important;
    }
    .align-items-xl-stretch {
        align-items: stretch !important;
    }
    .align-content-xl-start {
        align-content: flex-start !important;
    }
    .align-content-xl-end {
        align-content: flex-end !important;
    }
    .align-content-xl-center {
        align-content: center !important;
    }
    .align-content-xl-between {
        align-content: space-between !important;
    }
    .align-content-xl-around {
        align-content: space-around !important;
    }
    .align-content-xl-stretch {
        align-content: stretch !important;
    }
    .align-self-xl-auto {
        align-self: auto !important;
    }
    .align-self-xl-start {
        align-self: flex-start !important;
    }
    .align-self-xl-end {
        align-self: flex-end !important;
    }
    .align-self-xl-center {
        align-self: center !important;
    }
    .align-self-xl-baseline {
        align-self: baseline !important;
    }
    .align-self-xl-stretch {
        align-self: stretch !important;
    }
    .order-xl-first {
        order: -1 !important;
    }
    .order-xl-0 {
        order: 0 !important;
    }
    .order-xl-1 {
        order: 1 !important;
    }
    .order-xl-2 {
        order: 2 !important;
    }
    .order-xl-3 {
        order: 3 !important;
    }
    .order-xl-4 {
        order: 4 !important;
    }
    .order-xl-5 {
        order: 5 !important;
    }
    .order-xl-last {
        order: 6 !important;
    }
    .m-xl-0 {
        margin: 0 !important;
    }
    .m-xl-1 {
        margin: 0.25rem !important;
    }
    .m-xl-2 {
        margin: 0.5rem !important;
    }
    .m-xl-3 {
        margin: 1rem !important;
    }
    .m-xl-4 {
        margin: 1.5rem !important;
    }
    .m-xl-5 {
        margin: 3rem !important;
    }
    .m-xl-auto {
        margin: auto !important;
    }
    .mx-xl-0 {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    .mx-xl-1 {
        margin-right: 0.25rem !important;
        margin-left: 0.25rem !important;
    }
    .mx-xl-2 {
        margin-right: 0.5rem !important;
        margin-left: 0.5rem !important;
    }
    .mx-xl-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important;
    }
    .mx-xl-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
    }
    .mx-xl-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important;
    }
    .mx-xl-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
    .my-xl-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
    .my-xl-1 {
        margin-top: 0.25rem !important;
        margin-bottom: 0.25rem !important;
    }
    .my-xl-2 {
        margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;
    }
    .my-xl-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }
    .my-xl-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important;
    }
    .my-xl-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important;
    }
    .my-xl-auto {
        margin-top: auto !important;
        margin-bottom: auto !important;
    }
    .mt-xl-0 {
        margin-top: 0 !important;
    }
    .mt-xl-1 {
        margin-top: 0.25rem !important;
    }
    .mt-xl-2 {
        margin-top: 0.5rem !important;
    }
    .mt-xl-3 {
        margin-top: 1rem !important;
    }
    .mt-xl-4 {
        margin-top: 1.5rem !important;
    }
    .mt-xl-5 {
        margin-top: 3rem !important;
    }
    .mt-xl-auto {
        margin-top: auto !important;
    }
    .me-xl-0 {
        margin-right: 0 !important;
    }
    .me-xl-1 {
        margin-right: 0.25rem !important;
    }
    .me-xl-2 {
        margin-right: 0.5rem !important;
    }
    .me-xl-3 {
        margin-right: 1rem !important;
    }
    .me-xl-4 {
        margin-right: 1.5rem !important;
    }
    .me-xl-5 {
        margin-right: 3rem !important;
    }
    .me-xl-auto {
        margin-right: auto !important;
    }
    .mb-xl-0 {
        margin-bottom: 0 !important;
    }
    .mb-xl-1 {
        margin-bottom: 0.25rem !important;
    }
    .mb-xl-2 {
        margin-bottom: 0.5rem !important;
    }
    .mb-xl-3 {
        margin-bottom: 1rem !important;
    }
    .mb-xl-4 {
        margin-bottom: 1.5rem !important;
    }
    .mb-xl-5 {
        margin-bottom: 3rem !important;
    }
    .mb-xl-auto {
        margin-bottom: auto !important;
    }
    .ms-xl-0 {
        margin-left: 0 !important;
    }
    .ms-xl-1 {
        margin-left: 0.25rem !important;
    }
    .ms-xl-2 {
        margin-left: 0.5rem !important;
    }
    .ms-xl-3 {
        margin-left: 1rem !important;
    }
    .ms-xl-4 {
        margin-left: 1.5rem !important;
    }
    .ms-xl-5 {
        margin-left: 3rem !important;
    }
    .ms-xl-auto {
        margin-left: auto !important;
    }
    .p-xl-0 {
        padding: 0 !important;
    }
    .p-xl-1 {
        padding: 0.25rem !important;
    }
    .p-xl-2 {
        padding: 0.5rem !important;
    }
    .p-xl-3 {
        padding: 1rem !important;
    }
    .p-xl-4 {
        padding: 1.5rem !important;
    }
    .p-xl-5 {
        padding: 3rem !important;
    }
    .px-xl-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
    .px-xl-1 {
        padding-right: 0.25rem !important;
        padding-left: 0.25rem !important;
    }
    .px-xl-2 {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }
    .px-xl-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }
    .px-xl-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }
    .px-xl-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
    }
    .py-xl-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    .py-xl-1 {
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
    }
    .py-xl-2 {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }
    .py-xl-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }
    .py-xl-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
    }
    .py-xl-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
    }
    .pt-xl-0 {
        padding-top: 0 !important;
    }
    .pt-xl-1 {
        padding-top: 0.25rem !important;
    }
    .pt-xl-2 {
        padding-top: 0.5rem !important;
    }
    .pt-xl-3 {
        padding-top: 1rem !important;
    }
    .pt-xl-4 {
        padding-top: 1.5rem !important;
    }
    .pt-xl-5 {
        padding-top: 3rem !important;
    }
    .pe-xl-0 {
        padding-right: 0 !important;
    }
    .pe-xl-1 {
        padding-right: 0.25rem !important;
    }
    .pe-xl-2 {
        padding-right: 0.5rem !important;
    }
    .pe-xl-3 {
        padding-right: 1rem !important;
    }
    .pe-xl-4 {
        padding-right: 1.5rem !important;
    }
    .pe-xl-5 {
        padding-right: 3rem !important;
    }
    .pb-xl-0 {
        padding-bottom: 0 !important;
    }
    .pb-xl-1 {
        padding-bottom: 0.25rem !important;
    }
    .pb-xl-2 {
        padding-bottom: 0.5rem !important;
    }
    .pb-xl-3 {
        padding-bottom: 1rem !important;
    }
    .pb-xl-4 {
        padding-bottom: 1.5rem !important;
    }
    .pb-xl-5 {
        padding-bottom: 3rem !important;
    }
    .ps-xl-0 {
        padding-left: 0 !important;
    }
    .ps-xl-1 {
        padding-left: 0.25rem !important;
    }
    .ps-xl-2 {
        padding-left: 0.5rem !important;
    }
    .ps-xl-3 {
        padding-left: 1rem !important;
    }
    .ps-xl-4 {
        padding-left: 1.5rem !important;
    }
    .ps-xl-5 {
        padding-left: 3rem !important;
    }
    .text-xl-start {
        text-align: left !important;
    }
    .text-xl-end {
        text-align: right !important;
    }
    .text-xl-center {
        text-align: center !important;
    }
}