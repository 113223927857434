.img-card {
  width: 100%;
  height: 130px;
  object-fit: cover;
}
.img-card-list {
  width: 50px;
  height: 50px;
  object-fit: cover;
}
.card-content-md p {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0.4rem;
  margin-bottom: 2px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
}

ion-card.card-home {
  --background: #ffffff;
  position: relative;
}
ion-card-title.title-card{
  min-height: 40px;
}
ion-card-content.content-card {
  min-height: 115px;
  max-height: 115px;
}

/* content card */

.title-card {
  font-weight: bold;
  font-size: 17px;
}

.fullname-account {
  font-size: 15px;
  color: #424242;
}

.street {
  font-size: 15px;
  color: #424242;
}

.date {
  font-size: 12px;
  color: #424242;
}

/* icone */

.icon-account {
  width: auto;
  max-height: 100%;
  min-width: max-content;
  padding: 0;
  /* reset */
}

.icon-pdf {
  position: absolute;
  bottom: 15px;
  right: 15px;
  min-width: min-content;
  max-width: 10%;
  height: auto;
  padding: 0;
  /* reset */
}