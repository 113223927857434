.cursor-pointer {
    cursor: pointer;
}

.scroll-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
}
.scroll-container .indicator {
    height: 100%;
}

.scroll-to-top {
    position: fixed;
    bottom: 0px;
    right: 20px;
    width: 70px;
    height: 70px;
    border-radius: 100%;
    color: #fff;
    z-index: 99999;
}
.scroll-to-top .icon {
    margin-top: 10px;
    animation: scrollTop 0.5s alternate ease infinite;
}
@keyframes scrollTop {
    from {
        transform: translateY(2px);
    }
    to {
        transform: translateY(-1px);
    }
}