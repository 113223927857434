.logo {
    height: 40px;
    text-align: left;
}

.name-account {
    font-size: 15px;
}

.icon-account {
    max-height: 30px;
}

.icon-user{
   vertical-align: middle;
}

.row {
    display: flex;
    flex-wrap: wrap;
}

.icon-logout {
    height: 1.5rem;
    width: auto;
}

.role {
    font-size: 12px;
    font-weight: bolder;
}

.headerGrid {
    padding: 0px;
}

div.navbar{
    background-color: white;
    color: #555
}