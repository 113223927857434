/*
 * "my-custom-interface" needs to be passed in through
 * the cssClass of the interface options for this to work
 */

.my-custom-interface .select-interface-option {
    --button-color: #971e49;
    --button-color-hover: #79193b;
}

.label-modal {
    color: var (--ion-color-tertiary);
}

/* modal add */

hr {
    height: 1px;
    background-color: #D1D3D3;
}

ion-select::part(icon) {
    color: #33a7b2;
    opacity: 1;
    position: absolute;
    right: 10px;
}

ion-item.select::part(native) {
    background-color: transparent;
    border: solid 1px #DBDADA;
    border-radius: 5px;
}

ion-select::part(placeholder) {
    position: absolute;
    left: 0px;
}

.filter {
    background-color: #ffffff;
    border-radius: 5px;
}