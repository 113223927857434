/* Works - pass "my-custom-class" in cssClass to increase specificity */

.my-modal-add-problematic .modal-wrapper {
  border-radius: 10px;
}

.my-modal-add-user .modal-wrapper {
  border-radius: 10px;
}

.my-modal-rec-audio .modal-wrapper {
  border-radius: 10px;
}

.my-modal-add-inspection .modal-wrapper {
  border-radius: 10px;
}

.my-modal-add-inspection ol {
  padding: 0px;
}

.my-modal-delete .modal-wrapper {
  border-radius: 10px;
}

.my-modal-addPhoto .modal-wrapper {
  border-radius: 10px;
}

.my-modal-alert .modal-wrapper {
  border-radius: 10px;
}
.my-modal-group .modal-wrapper {
  border-radius: 10px;
}

.my-modal-add-problematic {
  --min-height: 90%;
  --min-width: 80%;
  --ion-background-color: #ffffff;
}
.my-modal-camera {
  /* --min-height: 90%; */
  --min-width: 40%;
  --ion-background-color: #ffffff;
}

.my-modal-rec-audio {
  /* --min-height: 65%;
  --min-width: auto; */
  background-color: rgba(0, 0, 0, 0.40);
  --ion-background-color: #ffffff;
}

.my-modal-add-inspection {
  --min-height: 65%;
  --min-width: auto;
  --ion-background-color: #ffffff;
}

.my-modal-addPhoto {
  --min-height: 50%;
  --min-width: auto;
  --ion-background-color: #ffffff;
}

.my-modal-alert {
  --max-height: 20% !important;
  --min-height: 20%;
  --min-width: auto;
  --ion-background-color: #ffffff !important;
  background-color: rgba(0, 0, 0, 0.40);
}

@media (min-width: 320px) {
  .my-modal-alert {
    --max-height: 40% !important;
    --min-height: 20%;
    --min-width: auto;
    --ion-background-color: #ffffff !important;
    background-color: rgba(0, 0, 0, 0.40);
  }
  .my-modal-add-user {
    --max-height: 65% !important;
    --min-height: 65%;
    --min-width: auto;
    --ion-background-color: #ffffff !important;
  }
  .my-modal-add-group {
    --max-height: 50% !important;
    --min-height: 50%;
    --min-width: auto;
    --ion-background-color: #ffffff !important;
  }
  .my-modal-approve{
    --max-height: 280px !important;
    --max-height: 280px !important;
    --min-width: auto;
    --ion-background-color: #ffffff !important;
  }
  .modal-translate{
    --min-height: 210px !important;
    --max-height: 210px !important;
    --min-width: auto;
    --ion-background-color: #ffffff !important;
  }
}
@media (min-width: 768px) {
  .my-modal-alert {
    --max-height: 20% !important;
    --min-height: 20%;
    --min-width: auto;
    --ion-background-color: #ffffff !important;
    background-color: rgba(0, 0, 0, 0.40);
  }
  .my-modal-add-user {
    --max-height: 65% !important;
    --min-height: 65%;
    --min-width: auto;
    --ion-background-color: #ffffff !important;
  }
  .my-modal-add-group {
    --max-height: 50% !important;
    --min-height: 50%;
    --min-width: auto;
    --ion-background-color: #ffffff !important;
  }
  .my-modal-approve{
    --max-height: 280px !important;
    --max-height: 280px !important;
    --min-width: auto;
    --ion-background-color: #ffffff !important;
  }
  .modal-translate{
    --min-height: 210px !important;
    --max-height: 210px !important;
    --min-width: auto;
    --ion-background-color: #ffffff !important;
  }
}

@media (min-width: 1024px) {
  .my-modal-alert {
    --max-height: 30% !important;
    --min-height: 20%;
    --min-width: auto;
    --ion-background-color: #ffffff !important;
  }
  .my-modal-add-user {
    --max-height: 60% !important;
    --min-height: 60%;
    --min-width: auto;
    --ion-background-color: #ffffff !important;
  }
  .my-modal-group {
    --max-height: 80% !important;
    --min-height: 50%;
    --min-width: 80%;
    --ion-background-color: #ffffff !important;
  }
  .my-modal-approve{
    --max-height: 280px !important;
    --max-height: 280px !important;
    --min-width: auto;
    --ion-background-color: #ffffff !important;
  }
  .modal-translate{
    --min-height: 210px !important;
    --max-height: 210px !important;
    --min-width: auto;
    --ion-background-color: #ffffff !important;
  }
}

@media (min-width: 1440px) {
  .my-modal-alert {
    --max-height: 25% !important;
    --min-height: 20%;
    --min-width: auto;
    --ion-background-color: #ffffff !important;
  }
  .my-modal-add-user {
    --max-height: 60% !important;
    --min-height: 60%;
    --min-width: auto;
    --ion-background-color: #ffffff !important;
  }
  .my-modal-group {
    --max-height: 50% !important;
    --min-height: 50%;
    --min-width: auto;
    --ion-background-color: #ffffff !important;
  }
  .my-modal-approve{
    --max-height: 280px !important;
    --max-height: 280px !important;
    --min-width: auto;
    --ion-background-color: #ffffff !important;
  }
  .modal-translate{
    --min-height: 210px !important;
    --max-height: 210px !important;
    --min-width: auto;
    --ion-background-color: #ffffff !important;
  }
}

/* screen large */

@media (min-width: 1920px) {
  .my-modal-delete {
    --max-height: 40% !important;
    --min-height: 20%;
    --min-width: auto;
    --ion-background-color: #ffffff !important;
  }
  .my-modal-alert {
    --max-height: 20% !important;
    --min-height: 20%;
    --min-width: auto;
    --ion-background-color: #ffffff !important;
  }
  .my-modal-add-user {
    --max-height: 50% !important;
    --min-height: 50%;
    --min-width: auto;
    --ion-background-color: #ffffff !important;
  }
  .my-modal-group {
    --max-height: 50% !important;
    --min-height: 30%;
    --min-width: auto;
    --ion-background-color: #ffffff !important;
  }
  .my-modal-approve{
    --max-height: 280px !important;
    --max-height: 280px !important;
    --min-width: auto;
    --ion-background-color: #ffffff !important;
  }
  .modal-translate{
    --min-height: 210px !important;
    --max-height: 210px !important;
    --min-width: auto;
    --ion-background-color: #ffffff !important;
  }
}

.my-modal-delete {
  --max-height: 40%;
  --min-height: 20%;
  --min-width: auto;
  --ion-background-color: #ffffff;
}

.my-modal-filter {
  background: #ffffff;
  border-radius: 0px;
  --ion-background-color: #ffffff;
}

@media screen and (max-width: 576px) {
  .my-modal-add-inspection {
    background: #ffffff;
    border-radius: 0px;
    --ion-background-color: #ffffff;
  }
  .my-modal-add-problematic {
    background: #ffffff;
    border-radius: 0px;
    --ion-background-color: #ffffff;
  }
  .my-modal-group {
    --max-height: 50% !important;
    --min-height: 50%;
    --min-width: auto;
    --ion-background-color: #ffffff !important;
  }
  .modal-translate{
    --min-height: 210px !important;
    --max-height: 210px !important;
    --min-width: auto;
    --ion-background-color: #ffffff !important;
  }
}