.img-gallery {
  width: 100%;
  height: 300px;
  object-fit: contain;
}

ion-badge.badge-gallery {
  padding: 7px;
  position: absolute;
  top: 9px;
  left: 48px;
}
.gallery-checkbox{
  position: absolute;
  top: 13px;
  left: 10px;
}