ion-title .footer-text {
    text-align: center;
    font-size: 12px;
}

.footer {
    z-index: -10px;
    position: relative;
    bottom: 0;
}

@media (min-width: 576px) {
    .footer {
        z-index: -10px;
        position: fixed;
        bottom: 0;
    }
}