div.badge-list {
    padding: 7px;
    border-radius: 3px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.5rem;
    text-align: center;
    display: block;
    width: 50%;
}


@media screen and (min-width: 1200px) {
    div.badge-list {
        padding: 7px;
        border-radius: 3px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 0.8rem;
        text-align: center;
        display: block;
        width: 50%;
    }
}