.bg-list-white {
    background-color: #f3f3f3;
}

ion-row.bg-list-white:nth-child(2n){
    background-color: #ffffff;
}



.img-list {
    height: 54px;
    width: 73px;
}

.title-list {
    font-size: 0.5rem;
    text-align: left;
    line-height: 15px;
}

.city {
    font-size: 0.5rem;
}

.address {
    font-size: 0.5rem;
}

.economic-unit {
    font-size: 0.5rem;
}

.date-list {
    font-size: 0.5rem;
}

.bm-list {
    font-size: 0.5rem;
}

.text-row-list {
    font-size: 0.5rem;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 15px;
}
.img-list{
    height: auto;
    width: 100%;
}

/* xl dispositivo */
@media screen and (min-width: 1200px) {
    .title-list {
        font-size: 1rem;
        text-align: left;
        line-height: 15px;
    }
    
    .city {
        font-size: 1rem;
    }
    
    .address {
        font-size: 1rem;
    }
    
    .economic-unit {
        font-size: 1rem;
    }
    
    .date-list {
        font-size: 1rem;
    }
    
    .bm-list {
        font-size: 1rem;
    }
    
    .text-row-list {
        font-size: 1rem;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 15px;
    }
    .img-list{
        height: 80px;
        width: 80px;
    }
}

/* xl dispositivo */
@media screen and (max-width: 1024px) {
    .title-list {
        font-size: 0.8rem;
        text-align: left;
        line-height: 15px;
    }
    
    .city {
        font-size: 0.8rem;
    }
    
    .address {
        font-size: 0.8rem;
    }
    
    .economic-unit {
        font-size: 0.8rem;
    }
    
    .date-list {
        font-size: 0.8rem;
    }
    
    .bm-list {
        font-size: 0.8rem;
    }
    
    .text-row-list {
        font-size: 0.8rem;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 15px;
    }
    .img-list{
        height: 80px;
        width: 80px;
    }
}