div.accordionCustom{
    width:100%;
    height:50px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    border-radius: 5px;
    cursor: pointer;
}

button.accordionCustom{
    background-color: transparent;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}


div.accordionCustom-content{
    max-height:0;
    overflow-y: hidden;
    transition: 0.7s;

}

div.accordionCustom-content.active{
    max-height:unset;
    
}