.white {
    background-color: #FFFFFF;
}

.sidebar {
    height: 100vh;
    width: 8vw;
    z-index: 11;
    overflow: hidden;
    position: fixed;
}

.icon-sidebar {
    height: 20px;
    width: auto;
}

.title-sidebar {
    font-weight: bold;
    font-size: 13px;
}

.tooltip{
    vertical-align: baseline !important;
    font-size: calc(1.275rem + 0.3vw) !important;
    margin-left: 1rem !important;
}